<template>
  <div class="home-view">
    <!-- This section will only be shown to authenticated users -->
    <div v-if="authStatus && authStatus.isAuthenticated" class="stats">
      <div v-if="stats" class="dashboard-stats">
        <div class="stat-card">
          <h3>Total Posts</h3>
          <p>{{ stats.totalPosts }}</p>
        </div>
        <div class="stat-card">
          <h3>New Messages</h3>
          <p>{{ stats.newMessages }}</p>
        </div>
        <div class="stat-card">
          <h3>Friends</h3>
          <p>{{ stats.friendsOnline }}</p>
        </div>
      </div>
    </div>

    <!-- This section will be shown to all users -->
    <div v-if="loading" class="load-panel">
      <div class="loader"></div>
      Loading...
    </div>
    <div v-else-if="error">{{ error }}</div>
    <div v-else class="dynamic-content" v-html="content"></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { fetchAuthStatus } from '@/services/auth'
import { fetchDashboardData } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const stats = ref(null);
const authStatus = ref(null);
const content = ref('');
const loading = ref(true);
const error = ref(null);

// Define the base URL for your HTML content
const BASE_CONTENT_URL = process.env.VUE_APP_BASE_CONTENT_URL;

const loadHomeContent = async () => {
  loading.value = true;
  error.value = null;
  try {
    const response = await fetch(`${BASE_CONTENT_URL}homepage.html`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const html = await response.text();
    
    // Use DOMParser to parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    
    // Extract the body content
    const bodyElement = doc.querySelector('body');
    if (bodyElement) {
      content.value = bodyElement.innerHTML;
    } else {
      throw new Error('No body content found in the HTML');
    }
    
    log.debug("Home content loaded");
  } catch (err) {
    error.value = 'Failed to load home content. Please try again later.';
    console.error('Error fetching home content:', err);
  } finally {
    loading.value = false;
  }
};

const loadDashboardData = async () => {
  if (authStatus.value.isAuthenticated) {
    try {
      log.debug("User is authenticated");
      const data = await fetchDashboardData();
      stats.value = data.stats;
    } catch (error) {
      log.error('Failed to fetch dashboard data:', error);
    }
  }
};

onMounted(async () => {
  log.trace("Starting to mount home page.");
  try {
    authStatus.value = await fetchAuthStatus();
    await Promise.all([loadHomeContent(), loadDashboardData()]);
  } catch (error) {
    log.error('Error during home page mount:', error);
  }
});
</script>

<style scoped>
.load-panel {
  text-align: center;
}

.load-panel .loader {
  text-align: center;
  height: 10em;
  width: 10em;
  margin: auto;
  margin-bottom: 2em;
}

.dashboard-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.stat-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  flex: 1;
  margin: 0 10px;
}

.stat-card h3 {
  margin-bottom: 10px;
  color: #333;
}

.stat-card p {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.dynamic-content :deep(h1) {
  margin-bottom: 20px;
}

.dynamic-content :deep(h2) {
  margin-bottom: 0px;
}

.dynamic-content :deep(p) {
  margin-bottom: 15px;
}
</style>
