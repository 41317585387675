<template>
    <div>
        <div class="title-container">
            <h1>My Lists</h1>
            <button class="button is-success is-small is-rounded" @click.stop="openCreateListModal">
                <FeatherIcon icon="plus" size="18" />
            </button>
            <button class="button" @click.stop="openCreateListModal">
                <FeatherIcon icon="upload" size="18" /> Import
            </button>
        </div>
        <div class="list-controls">
            <div class="control">
                <input class="input" type="text" v-model="searchText" placeholder="Search lists..." 
                    @keyup="handleKeyPress"
                    @blur="handleBlur">
            </div>
            <div class="control hide-on-mobile">
                <div class="select">
                    <select v-model="pageSize">
                        <option :value="10">10 per page</option>
                        <option :value="20">20 per page</option>
                        <option :value="50">50 per page</option>
                    </select>
                </div>
            </div>
            <div class="control">
                <button @click="toggleColumnSelector" class="button is-primary hide-on-mobile">
                    <FeatherIcon icon="columns" size="16" />
                    <span class="button-label">Toggle Columns</span>
                </button>
                <button class="button  is-primary" @click="refreshLists">
                    <FeatherIcon icon="refresh-cw" size="16" />
                    <span class="button-label">Refresh</span>
                </button>
            </div>
        </div>
        <div v-if="isLoading" class="loader-container">
            <div class="loader"></div>
        </div>
        <div v-else-if="!lists || lists.length === 0" class="no-lists-message">
            <div class="message-content">
                <p>No lists available. Create a new list to get started!</p>
                <img src="@/assets/curalist-logo.svg" alt="Empty list" class="empty-list-image">
            </div>
        </div>
        <div v-else class="list-grid-view">

            <div class="table-container">
                <table class="table is-fullwidth is-hoverable">
                    <thead>
                        <tr>
                            <th v-for="column in visibleColumns" :key="column.key" @click="sort(column.key)"
                                :class="column.class">
                                <div style="white-space: nowrap;">{{ column.label }}
                                    <FeatherIcon :icon="getSortIcon(column.key)" size="14" />
                                </div>
                            </th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="list in paginatedAndFilteredLists" :key="list.listkey" class="clickable-row">
                            <td v-for="column in visibleColumns" :key="column.key" @click="navigateToList(list)"
                                :class="column.class">
                                <template v-if="column.key === 'isPublic'">
                                    <div style="display: flex;">
                                        <button v-if="list.isPublic" class="button is-success" >Public</button>
                                        <button v-else class="button">Private</button>
                                    </div>
                                </template>
                                <template v-else-if="column.key === 'rating'">
                                    <div class="rating">
                                        <FeatherIcon v-for="star in 5" :key="star" icon="star" size="14"
                                            :class="{ 'is-filled': list.rating >= star }" />
                                    </div>
                                </template>
                                <template v-else-if="['createdOn', 'lastUpdatedOn'].includes(column.key)">
                                    {{ formatDate(list[column.key]) }}
                                </template>
                                <template v-else>
                                    {{ list[column.key] }}
                                </template>
                            </td>
                            <td>
                                <button class="button is-small is-rounded" @click.stop="openEditPopup(list)">
                                    <FeatherIcon icon="more-vertical" size="18" class="edit-icon" />
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                <a class="pagination-previous" @click="previousPage" v-if="currentPage === 1" disabled>
                    <FeatherIcon icon="arrow-left" size="24" />
                    <span class="hide-on-mobile">Previous</span>
                </a>
                <a class="pagination-previous" @click="previousPage" v-else>
                    <FeatherIcon icon="arrow-left" size="24" />
                    <span class="hide-on-mobile">Previous</span>
                </a>

                <a class="pagination-next" @click="nextPage" v-if="currentPage === totalPages" disabled>
                    <span class="hide-on-mobile">Next</span>
                    <FeatherIcon icon="arrow-right" size="24" />
                </a>
                <a class="pagination-next" @click="nextPage" v-else>
                    <span class="hide-on-mobile">Next</span>
                    <FeatherIcon icon="arrow-right" size="24" />
                </a>
                <ul class="pagination-list hide-on-mobile">
                    <li v-for="page in totalPages" :key="page">
                        <a class="pagination-link" :class="{ 'is-current': currentPage === page }"
                            @click="goToPage(page)">{{ page
                            }}</a>
                    </li>
                </ul>
            </nav>

            <!-- Modal for Column Selection -->
            <div class="modal" :class="{ 'is-active': isColumnSelectorVisible }">
                <div class="modal-background" @click="toggleColumnSelector"></div>
                <div class="modal-content">
                    <div class="card">
                        <div class="card-head">
                            <p class="card-head-title">Select Columns</p>
                            <button class="card-head-icon" @click="toggleColumnSelector">
                                <FeatherIcon icon="x" size="24" />
                            </button>
                        </div>
                        <div class="card-content">
                            <div class="content">
                                <div v-for="column in columns" :key="column.key" class="field">
                                    <div class="control">
                                        <label class="checkbox">
                                            <input type="checkbox" v-model="column.visible">
                                            {{ column.label }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer class="card-footer">
                            <a class="card-footer-item" @click="toggleColumnSelector">Close</a>
                        </footer>
                    </div>
                </div>
            </div>
        </div>

        <!-- Create New List Modal -->
        <div class="modal" :class="{ 'is-active': isCreateListModalVisible }">
            <div class="modal-background" @click="closeCreateListModal"></div>
            <div class="modal-content">
                <div class="box">
                    <h2 class="title is-4">Create New List</h2>
                    <div class="field">
                        <label class="label">Title</label>
                        <div class="control">
                            <input class="input" type="text" v-model="newList.title" placeholder="Enter list title">
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Description</label>
                        <div class="control">
                            <textarea class="textarea" v-model="newList.description"
                                placeholder="Enter list description"></textarea>
                        </div>
                    </div>
                    <div class="field is-grouped">
                        <div class="control">
                            <button class="button is-link" @click="createList">Save</button>
                        </div>
                        <div class="control">
                            <button class="button is-link is-light" @click="closeCreateListModal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Edit List Popup -->
        <div class="modal" :class="{ 'is-active': isEditPopupVisible }">
            <div class="modal-background" @click="closeEditPopup"></div>
            <div class="modal-content">
                <div class="card" v-if="selectedList">
                    <div class="card-head">
                        <p class="card-head-title">Edit List</p>
                        <button class="card-head-icon" @click="closeEditPopup">
                            <FeatherIcon icon="x" size="24" />
                        </button>
                    </div>
                    <div class="card-content">
                        <div class="content">
                            <div class="field">
                                <label class="label">Title</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="selectedList.title">
                                </div>
                            </div>
                            <!-- Add more fields as needed -->
                        </div>
                    </div>
                    <footer class="card-footer">
                        <a class="card-footer-item" @click="saveListDetails">Save</a>
                        <a class="card-footer-item" @click="closeEditPopup">Cancel</a>
                    </footer>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import FeatherIcon from '@/components/FeatherIcon.vue';
import { fetchLists, updateList, createList as apiCreateList } from '@/services/api';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import log from 'loglevel';
import { debounce } from 'lodash-es';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

// Use the auth store
const authStore = useAuthStore();
const { userkey } = storeToRefs(authStore);

const router = useRouter();

const lists = ref([]);
const isTyping = ref(false);

const currentPage = ref(1);
const pageSize = ref(10);
const sortBy = ref('title');
const sortDesc = ref(false);
const searchText = ref(''); // Add this line

const totalItems = ref(0);
const isColumnSelectorVisible = ref(false);
const isEditPopupVisible = ref(false);
const selectedList = ref(null);
const isCreateListModalVisible = ref(false);
const newList = ref({
    title: '',
    description: ''
});

const columns = ref([
    { key: 'isPublic', label: 'Status', visible: true, class: 'hide-on-mobile' },
    { key: 'title', label: 'Title', visible: true },
    { key: 'totalItems', label: 'Items', visible: true },
    { key: 'createdOn', label: 'Created', visible: false, class: 'hide-on-mobile' },
    { key: 'lastUpdatedOn', label: 'Updated', visible: true, class: 'hide-on-mobile' }
]);

const visibleColumns = computed(() => columns.value.filter(column => column.visible));

const isLoading = ref(true);

onMounted(async () => {
    log.trace('onMounted started');
    await refreshLists();
    log.trace('onMounted completed');
});

const toggleColumnSelector = () => {
    isColumnSelectorVisible.value = !isColumnSelectorVisible.value;
};

const paginatedAndFilteredLists = computed(() => {
    return lists.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / pageSize.value);
});

const sort = (column) => {
    const previousSortBy = sortBy.value;
    const previousSortDesc = sortDesc.value;

    if (sortBy.value === column) {
        sortDesc.value = !sortDesc.value;
    } else {
        sortBy.value = column;
        sortDesc.value = false;
    }

    // Check if sorting has changed
    if (previousSortBy !== sortBy.value || previousSortDesc !== sortDesc.value) {
        log.debug('Sorting changed. Refreshing list...');
        refreshLists();
    } else {
        log.debug('Sorting unchanged. No refresh needed.');
    }
};

const getSortIcon = (column) => {
    if (sortBy.value !== column) return 'chevron-up';
    return sortDesc.value ? 'chevron-down' : 'chevron-up';
};

const previousPage = async () => {
    if (currentPage.value > 1) currentPage.value--;
    await refreshLists();
};

const nextPage = async () => {
    if (currentPage.value < totalPages.value) currentPage.value++;
    await refreshLists();
};

const goToPage = async (page) => {
    currentPage.value = page;
    await refreshLists();
};

const navigateToList = (list) => {
    router.push(`/users/${list.userkey}/lists/${list.listkey}`);
};

const formatDate = (dateString) => {
    log.debug('formatting date string input:', dateString);
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    }).replace(',', '');
};

const openEditPopup = (list) => {
    console.log('Opening edit popup for list:', list); // Add this for debugging
    selectedList.value = { ...list }; // Create a new object to ensure reactivity
    isEditPopupVisible.value = true;
};

const closeEditPopup = () => {
    isEditPopupVisible.value = false;
    selectedList.value = null;
};

const saveListDetails = async () => {
    if (selectedList.value) {
        try {
            log.debug('Saving list details:', selectedList.value);
            const response = await updateList(
                selectedList.value.userkey,
                selectedList.value.listkey,
                {
                    title: selectedList.value.title,
                    // Add other fields here as needed
                }
            );

            if (response.successful && response.totalAffectedItems === 1) {
                // Update the list in the local state
                const index = lists.value.findIndex(list =>
                    list.userkey === selectedList.value.userkey && list.listkey === selectedList.value.listkey
                );
                if (index !== -1) {
                    lists.value[index] = { ...lists.value[index], ...selectedList.value };
                }

                log.info('List updated successfully');
                closeEditPopup();
            } else {
                log.warn('List update may not have been successful');
                // Optionally show a warning to the user
            }
        } catch (error) {
            log.error('Failed to update list:', error);
            // Handle error (e.g., show an error message to the user)
        }
    }
};

// Add a new function to refresh the lists
const refreshLists = async () => {
    try {
        log.trace('refreshing the list content'); // For debugging
        
        isLoading.value = true;
        
        const sortDirection = sortDesc.value ? 'desc' : 'asc';
        const sortParam = `${sortBy.value},${sortDirection}`;
        
        log.debug('Refreshing lists with params:', {
            page: currentPage.value,
            pageSize: pageSize.value,
            sortBy: sortParam,
            searchText: searchText.value
        });

        const response = await fetchLists(
            currentPage.value,
            pageSize.value,
            sortParam,
            searchText.value
        );
        log.debug('Fetched lists:', response); // For debugging

        if (response && typeof response === 'object') {
            lists.value = response.values || [];
            totalItems.value = response.totalMatchingItems || 0;
            currentPage.value = response.page || currentPage.value;
            pageSize.value = response.pageSize || pageSize.value;
        } else {
            lists.value = [];
            totalItems.value = 0;
        }

        console.log('Updated lists.value:', lists.value); // For debugging
        log.info('Lists refreshed successfully');
    } catch (error) {
        log.error('Failed to refresh lists:', error);
        lists.value = [];
        totalItems.value = 0;
    } finally {
        isLoading.value = false;
    }
};

const openCreateListModal = () => {
    log.trace("Open modal to create new list");
    isCreateListModalVisible.value = true;
};

const closeCreateListModal = () => {
    isCreateListModalVisible.value = false;
    newList.value = { title: '', description: '' };
};

const createList = async () => {
    try {
        log.info('Creating new list:', newList);
        const createdList = await apiCreateList(userkey, newList.value);
        log.info('New list created successfully:', createdList);
        closeCreateListModal();
        await refreshLists();
    } catch (error) {
        log.error('Failed to create new list:', error);
        // Handle error (e.g., show an error message to the user)
    }
};

// Create a debounced version of the refresh function
const debouncedRefresh = debounce(async () => {
    if (isTyping.value) {
        await refreshLists(searchText.value);
        isTyping.value = false;
    }
}, 3000); // 30 seconds delay

// Watch for changes in the filter input
watch(searchText, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        log.debug('Search text changed:', newValue);
        isTyping.value = true;
        debouncedRefresh();
    }
});

// Handle blur event on the input
const handleBlur = async () => {
    log.trace('Input blurred!');
    if (isTyping.value) {
        log.debug('Input blurred, cancelling debounce and refreshing');
        debouncedRefresh.cancel();
        await refreshLists();
        isTyping.value = false;
    }
};

const handleKeyPress = async (event) => {
  if (event.key === 'Enter') {
    log.debug('Enter key pressed, triggering immediate refresh');
    debouncedRefresh.cancel(); // Cancel any pending debounced refresh
    isTyping.value = false;
    await refreshLists();
  }
};

</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
}

.button.is-small {
    height: 24px;
    width: 24px;
    background-color: #3273dc;
    color: white;
}

.button .icon {
    width: 16px;
    height: 16px;
    padding: 0 !important;
    margin: 0 !important;
}

.button .button-label {
    margin-left: 8px;
}

.no-lists-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    /* Adjust based on your header/footer height */
    text-align: center;
}

.message-content {
    max-width: 400px;
}

.message-content p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #4a4a4a;
}

.empty-list-image {
    width: 200px;
    height: auto;
    margin: 0 auto;
}

.list-controls {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.table-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}

.table th {
    cursor: pointer;
}

.table th span {
    margin-right: 5px;
}

.list-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 4px;
}

.rating {
    display: flex;
}

.rating .feather {
    color: #d4d4d4;
    margin-right: 2px;
}

.rating .is-filled {
    color: #ffd700;
}

.pagination-link.is-current {
    background-color: #7F00FF;
    border-color: #7F00FF;
}

/* Modal styles */
.modal.is-active {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: self-start;
    padding-top: 200px;
}

.modal-content {
    width: 80%;
    max-width: 500px;
    background: white;
    border-radius: 8px;
    z-index: 1000;
    /* Ensure the modal is on top */
    position: relative;
}

.modal-background {
    background: rgba(0, 0, 0, 0.5);
    /* Darken the background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    /* Ensure the background is below the modal content */
}

.card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    border-bottom: 1px solid #e0e0e0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-head-title {
    font-weight: bold;
    margin: 0;
}

.card-head-icon {
    background: none;
    border: none;
    cursor: pointer;
}

.card-content {
    padding: 1rem;
}

.card-footer {
    padding: 0;
}

.field {
    margin-bottom: 0.5rem;
}

.checkbox {
    display: flex;
    align-items: center;
}

.checkbox input[type="checkbox"] {
    margin-right: 0.5rem;
}

.clickable-row {
    cursor: pointer;
}

.clickable-row:hover {
    background-color: #f5f5f5;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.edit-icon {
    cursor: pointer;
    margin-left: auto;
}
</style>