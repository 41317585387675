<template>
    <h1>Search</h1>
    <div class="search-view">
        <!-- Search header -->
        <div class="search-header">
            <div class="container">
                <div class="instructions">Enter a search query to find lists or links.</div>
                <div class="field has-addons">
                    <div class="control is-expanded">
                        <input 
                            class="input is-medium" 
                            type="text" 
                            v-model="searchQuery" 
                            placeholder="Search for lists or links"
                            @keyup.enter="performSearch"
                        >
                    </div>
                    <div class="control">
                        <button class="button is-primary is-medium" @click="performSearch">
                            <FeatherIcon icon="search" />
                        </button>
                    </div>
                </div>
                <div class="mt-3 has-text-centered">
                    <a href="#" class="clear-search" @click.prevent="clearSearch">Clear Search</a>
                </div>
            </div>
        </div>

        <!-- Total matching items -->
        <div v-if="hasSearched" class="container mt-4">
            <p class="has-text-centered">
                <strong>{{ totalItems }}</strong> matching items found
            </p>
        </div>

        <!-- Search results -->
        <div v-if="hasSearched" class="container mt-4">
            <div v-if="searchResults && searchResults.length > 0">
                <div v-for="result in searchResults" :key="result.id" class="card mb-4">
                    <div class="card-content">
                        <p class="title is-4">{{ result.title }}</p>
                        <p class="subtitle is-6">{{ result.description }}</p>
                        <div class="content">
                            <p><strong>Total Items:</strong> {{ result.totalItems }}</p>
                            <p><strong>Last Updated:</strong> {{ formatDate(result.lastUpdatedOn) }}</p>
                            <p><strong>Added On:</strong> {{ formatDate(result.addedOn) }}</p>
                            <p><strong>Published On:</strong> {{ formatDate(result.publishedOn) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="has-text-centered mt-6">
                <p class="is-size-4">No results found</p>
            </div>
        </div>

        <!-- Pagination -->
        <div v-if="hasSearched && totalPages > 1" class="container mt-4">
            <nav class="pagination is-centered" role="navigation" aria-label="pagination">
                <a 
                    class="pagination-previous" 
                    @click="prevPage" 
                    :disabled="currentPage <= 1"
                    :class="{ 'is-disabled': currentPage <= 1 }"
                >
                    Previous
                </a>
                <a 
                    class="pagination-next" 
                    @click="nextPage" 
                    :disabled="currentPage >= totalPages"
                    :class="{ 'is-disabled': currentPage >= totalPages }"
                >
                    Next page
                </a>
                <ul class="pagination-list">
                    <li v-for="page in displayedPages" :key="page">
                        <a 
                            class="pagination-link" 
                            :class="{ 'is-current': page === currentPage }"
                            @click="goToPage(page)"
                        >
                            {{ page }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import FeatherIcon from '@/components/FeatherIcon.vue';
import { fetchSearchResults } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const router = useRouter();
const searchQuery = ref('');
const filters = ref({
    minRating: 0,
    minFollowers: 0,
    topics: '',
    searchType: 'all'
});

const searchResults = ref([]);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const itemsPerPage = 20;
const hasSearched = ref(false);

const performSearch = async () => {
    if (!searchQuery.value.trim()) {
        // Don't perform empty searches
        return;
    }

    // Update the URL with the search query
    router.push({
        path: '/search',
        query: {
            q: searchQuery.value,
            ...filters.value
        }
    });

    // Perform the search
    try {
        const response = await fetchSearchResults({
            query: searchQuery.value,
            ...filters.value,
            page: currentPage.value,
            itemsPerPage
        });
        
        // Update with the correct properties from the API response
        searchResults.value = response.values || [];
        totalPages.value = response.totalPages || 1;
        totalItems.value = response.totalMatchingItems || 0;
        hasSearched.value = true;

    } catch (error) {
        log.error('Error performing search:', error);
        searchResults.value = [];
        totalPages.value = 1;
        totalItems.value = 0;
        hasSearched.value = true;
    }
};

const clearSearch = () => {
    searchQuery.value = '';
    searchResults.value = [];
    currentPage.value = 1;
    totalPages.value = 1;
    totalItems.value = 0;
    hasSearched.value = false;
    
    // Reset filters if needed
    filters.value = {
        minRating: 0,
        minFollowers: 0,
        topics: '',
        searchType: 'all'
    };

    // Update URL to remove query parameters
    router.push({ path: '/search' });
};

const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        performSearch();
    }
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        performSearch();
    }
};

const goToPage = (page) => {
    currentPage.value = page;
    performSearch();
};

const displayedPages = computed(() => {
    const pages = [];
    const maxDisplayedPages = 5;
    const halfMaxPages = Math.floor(maxDisplayedPages / 2);

    let startPage = Math.max(1, currentPage.value - halfMaxPages);
    let endPage = Math.min(totalPages.value, startPage + maxDisplayedPages - 1);

    if (endPage - startPage + 1 < maxDisplayedPages) {
        startPage = Math.max(1, endPage - maxDisplayedPages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
    }

    return pages;
});

// Helper function to format dates
const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    return new Date(dateString).toLocaleDateString();
};
</script>

<style scoped>
.input {
    height: fit-content;
}

.search-view {
    padding-bottom: 2rem;
}

.search-header {
    padding: 1rem 0;
}

.instructions {
    margin-bottom: 1rem;
    text-align: center;
}

.field.has-addons {
    justify-content: center;
}

.field.has-addons .control:first-child {
    width: 100%;
    max-width: 600px;
}

.field.has-addons .control:not(:first-child):not(:last-child) .button {
    border-radius: 0;
}

.field.has-addons .control:last-child .button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.field.has-addons .control .input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.card {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.pagination-link.is-current {
    background-color: #3273dc;
    border-color: #3273dc;
    color: #fff;
}

.clear-search {
    color: #3273dc;
    text-decoration: none;
    font-size: 0.9rem;
}

.clear-search:hover {
    text-decoration: underline;
}

.pagination-previous.is-disabled,
.pagination-next.is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-previous,
.pagination-next {
    color: #3273dc;
    cursor: pointer;
    opacity: 1;
}

.pagination-previous:hover,
.pagination-next:hover {
    border-color: #3273dc;
    color: #363636;
}

.pagination-previous:not(.is-disabled),
.pagination-next:not(.is-disabled) {
    background-color: #3273dc;
    border-color: #3273dc;
    color: #fff;
}

.pagination-previous.is-disabled,
.pagination-next.is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #f5f5f5;
    border-color: #dbdbdb;
    color: #7a7a7a;
}

.pagination-previous:not(.is-disabled):hover,
.pagination-next:not(.is-disabled):hover {
    background-color: #2366d1;
    border-color: #2366d1;
    color: #fff;
}
</style>