<template>
  <h1>Add new item</h1>
  <div class="add-new-item-view">
    <div class="container">

      <div class="columns is-centered">
        <div class="column is-8">
          <div class="card">
            <div class="card-content">
              <div class="field">
                <label class="label">Link URL</label>
                <div class="control has-icons-left">
                  <input class="input" type="url" v-model="linkUrl" placeholder="https://example.com">
                  <span class="icon is-small is-left">
                    <FeatherIcon icon="link" />
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">Link Title</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" v-model="linkTitle" placeholder="Enter a title for your link">
                  <span class="icon is-small is-left">
                    <FeatherIcon icon="type" />
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">Description</label>
                <div class="control">
                  <textarea class="textarea" v-model="linkDescription"
                    placeholder="Add a description (optional)"></textarea>
                </div>
              </div>

              <div class="field">
                <label class="label">Tags</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" v-model="linkTags" placeholder="Enter tags separated by commas">
                  <span class="icon is-small is-left">
                    <FeatherIcon icon="tag" />
                  </span>
                </div>
              </div>

              <div class="field">
                <label class="label">Save to ...</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="selectedList">
                      <option value="existing">Existing list</option>
                      <option value="new">New list</option>
                    </select>
                  </div>
                </div>
              </div>

              <div  v-if="selectedList === 'existing'" class="field">
                <label class="label">Select list</label>
                <div class="control">
                  <div class="select is-fullwidth">
                    <select v-model="targetList">
                      <option selected="selected" value="">Select one ...</option>
                      <option v-for="list in userLists" :key="list.id" :value="list.id">
                        {{ list.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="selectedList === 'new'" class="field">
                <label class="label">New List Name</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" v-model="newListName" placeholder="Enter a name for your new list">
                  <span class="icon is-small is-left">
                    <FeatherIcon icon="list" />
                  </span>
                </div>
              </div>

              <div class="field mt-5">
                <div class="control">
                  <button class="button is-primary is-fullwidth" @click="saveItem" :disabled="!isFormValid">
                    <span class="icon">
                      <FeatherIcon icon="save" />
                    </span>
                    <span>Save Item</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import FeatherIcon from '@/components/FeatherIcon.vue';
import { saveNewItem, createList, fetchLists } from '@/services/api';

const router = useRouter();

const linkUrl = ref('');
const linkTitle = ref('');
const linkDescription = ref('');
const linkTags = ref('');
const selectedList = ref('');
const newListName = ref('');
const userLists = ref([]);

// Fetch user's lists when component mounts
fetchLists().then(lists => {
  userLists.value = lists;
});

const isFormValid = computed(() => {
  return linkUrl.value && linkTitle.value &&
    (selectedList.value || (selectedList.value === 'new' && newListName.value));
});

const saveItem = async () => {
  let listId = selectedList.value;

  if (selectedList.value === 'new') {
    // Create a new list
    const newList = await createList(newListName.value);
    listId = newList.id;
  }

  const newItem = {
    url: linkUrl.value,
    title: linkTitle.value,
    description: linkDescription.value,
    tags: linkTags.value.split(',').map(tag => tag.trim()),
    listId: listId
  };

  await saveNewItem(newItem);

  // Redirect to the list view or show a success message
  router.push({ name: 'ListView', params: { id: listId } });
};
</script>

<style scoped>
.add-new-item-view {
  padding: 2rem 0;
}

.card {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}

.button.is-primary {
  transition: all 0.3s ease;
}

.button.is-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
</style>