<template>
  <h1>Settings</h1>
  <div class="settings">
    <form @submit.prevent="saveSettings">
      <div class="form-group">
        <label for="startPage">Set my start page as</label>
        <select id="startPage" v-model="settings.startPage">
          <option value="home">Curalist Home</option>
          <option value="feed">The Discover Feed</option>
          <option value="my-lists">My Lists</option>
          <option value="my-list">One of my lists (must select one)</option>
        </select>
      </div>
      <div class="form-group">
        <label for="notifications">Communications</label>
        <input type="checkbox" id="notifications" v-model="settings.emailNotifications"> Allow email notifications
      </div>
      <div class="form-group">
        <label for="theme">Theme</label>
        Light
        <ThemeToggle v-model="settings.theme" />
        Dark
      </div>
      <button type="submit" class="save-button">Save Settings</button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { fetchUserPreferences, updateUserSettings } from '@/services/api';
import ThemeToggle from '@/components/ThemeToggle.vue';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const settings = ref({
  startPage: 'feed',
  emailNotifications: false,
  theme: 'light'
});

const getThemeFromLocalStorage = () => {
  return localStorage.getItem('theme');
};

const setThemeToLocalStorage = (theme) => {
  localStorage.setItem('theme', theme);
};

const applyTheme = (theme) => {
  document.body.className = theme;
  setThemeToLocalStorage(theme);
};

// Watch for changes in the theme setting
watch(() => settings.value.theme, (newTheme) => {
  applyTheme(newTheme);
});

onMounted(async () => {
  try {
    // First, try to fetch user preferences from the API
    const data = await fetchUserPreferences();
    log.debug("Mounting preferences from API:", data.data.preferences);
    
    const { 
      startPage = 'feed', 
      emailNotifications = false, 
      theme = 'light' 
    } = data.data.preferences || {};

    settings.value = {
      startPage,
      emailNotifications: Boolean(emailNotifications),
      theme: theme === 'dark' ? 'dark' : 'light'
    };
  } catch (error) {
    log.error('Failed to fetch user settings from API:', error);
    
    // If API fetch fails, try to get theme from localStorage
    const localTheme = getThemeFromLocalStorage();
    
    settings.value = {
      startPage: 'feed', // default value
      emailNotifications: false, // default value
      theme: localTheme ? (localTheme === 'dark' ? 'dark' : 'light') : 'light'
    };
  } finally {
    // Ensure the theme is applied and stored in localStorage
    applyTheme(settings.value.theme);
    setThemeToLocalStorage(settings.value.theme);
    log.debug("Settings after mounting:", settings.value);
  }
});

const saveSettings = async () => {
  try {
    log.debug("Saving settings:", settings.value);
    await updateUserSettings(settings.value);
    setThemeToLocalStorage(settings.value.theme); // Update localStorage when saving
    //alert('Settings saved successfully!');
  } catch (error) {
    log.error('Failed to save settings:', error);
    //alert('Failed to save settings. Please try again.');
  }
};
</script>

<style scoped>
.settings {
    margin: 0 auto;
    padding: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="startPage"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .save-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #0056b3;
  }

</style>