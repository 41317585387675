import apiService from './api';
import log from 'loglevel';

class AuthService {
  constructor() {   
    log.setLevel(process.env.VUE_APP_LOG_LEVEL);
    this.api = apiService; // Change this line
    this.CACHE_DURATION = 60000;
  }

  fetchAuthStatus = () => {
    const now = Date.now();
    log.debug('Fetching auth status from API component');

    if (this.authStatusCache && now - this.authStatusCacheTime < this.CACHE_DURATION) {
      log.debug('Returning cached auth status');
      return Promise.resolve(this.authStatusCache);
    }

    if (this.authStatusPromise) {
      log.debug('Returning pending auth status promise');
      return this.authStatusPromise;
    }

    this.authStatusPromise = this._fetchAuthStatus();

    return this.authStatusPromise.then(result => {
      this.authStatusCache = result;
      this.authStatusCacheTime = now;
      this.authStatusPromise = null;
      return result;
    }).catch(error => {
      this.authStatusPromise = null;
      throw error;
    });
  }

  _fetchAuthStatus = async () => {
    try {
      log.trace('Making API request to /auth/status');
      if (!this.api || typeof this.api.get !== 'function') {
        throw new Error('API not properly initialized');
      }
      const response = await this.api.get('/auth/status');
      log.debug('Auth status API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in _fetchAuthStatus:', error);
      throw error;
    }
  }

  fetchAuthProviders = async () => {
    try {
      log.trace('Making API request to /auth/providers');
      if (!this.api || typeof this.api.get !== 'function') {
        throw new Error('API not properly initialized');
      }
      const response = await this.api.get('/auth/providers');
      log.debug('API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in fetchAuthProviders:', error);
      throw error;
    }
  }

  login = async (credentials) => {
    try {
      log.debug('Making API request to /login', credentials);
      if (!this.api || typeof this.api.post !== 'function') {
        throw new Error('API not properly initialized');
      }
      const response = await this.api.post('/login', credentials);
      log.debug('Login API response:', response);
      return response.data;
    } catch (error) {
      log.error('Error in login:', error);
      throw error;
    }
  }

  logout = async () => {
    try {
      log.trace('Making API request to /account/logout');
      if (!this.api || typeof this.api.post !== 'function') {
        throw new Error('API not properly initialized');
      }
      const response = await this.api.post('/account/logout', {}, {
        withCredentials: true
      });

      log.debug('Logout API response:', response);

      if (response.status === 200) {
        log.info('Logout successful');
        return true;
      } else {
        log.warn('Unexpected response status:', response.status);
        throw new Error('Unexpected response from server during logout');
      }
    } catch (error) {
      log.error('Error in logout:', error);
      throw error;
    }
  }  
}

const authService = new AuthService();

export const fetchAuthStatus = authService.fetchAuthStatus.bind(authService);
export const fetchAuthProviders = authService.fetchAuthProviders.bind(authService);
export const login = authService.login.bind(authService);
export const logout = authService.logout.bind(authService);

export default authService;
