<template>
    <h1>Administration</h1>
    <div class="admin-view">

        <AdminMenu :menuItems="menuItems" />

        <div class="columns">
            <div class="column is-3">
                <div class="field">
                    <label class="label">Time Range</label>
                    <div class="control">
                        <div class="select is-fullwidth">
                            <select v-model="selectedTimeRange" @change="updateData">
                                <option value="day">Today</option>
                                <option value="day-1">Yesterday</option>
                                <option value="week">This week</option>
                                <option value="week-1">Last Week</option>
                                <option value="month">This Month</option>
                                <option value="month-1">Last Month</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">Total Users</h2>
                    <p class="title">{{ stats.totalUsers }}</p>
                </div>
            </div>
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">Active Users</h2>
                    <p class="title">{{ stats.activeUsers }}</p>
                </div>
            </div>
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">New Sign-ups</h2>
                    <p class="title">{{ stats.newSignups }}</p>
                </div>
            </div>
            <div class="column is-3">
                <div class="box">
                    <h2 class="subtitle">Total Lists</h2>
                    <p class="title">{{ stats.totalLists }}</p>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-6">
                <div class="box">
                    <LineChart :chartData="userGrowthData" />
                </div>
            </div>
            <div class="column is-6">
                <div class="box">
                    <LineChart :chartData="listCreationData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted,watch } from 'vue';
import { fetchStats } from '@/services/api';
import AdminMenu from '@/components/AdminMenu.vue';
import LineChart from '@/components/LineChart.vue';
import menuItems from '@/data/adminMenuItems.json';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const selectedTimeRange = ref('week');
const selectedUserType = ref('all');

const stats = ref({
    totalUsers: 0,
    activeUsers: 0,
    newSignups: 0,
    totalLists: 0
});

const userGrowthData = ref({});
const listCreationData = ref({});

const updateData = async () => {
    try {
        const data = await fetchStats(selectedTimeRange.value, selectedUserType.value);
        stats.value = data.totals;

        // Update chart data
        if (data.chartData) {
            userGrowthData.value = data.chartData.userGrowth;
            listCreationData.value = data.chartData.listCreation;
        }

    } catch (error) {
        log.error('Error fetching admin stats:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
    }
};

onMounted(() => {
  updateData();
});

watch([selectedTimeRange, selectedUserType], () => {
    updateData();
});
</script>

<style scoped>
.admin-view {
    padding: 20px;
}

.box {
    height: 100%;
}
</style>