import { createRouter, createWebHistory } from 'vue-router'
import { fetchSettings } from '@/services/api'
import { fetchAuthStatus } from '@/services/auth'
import AddNewItemView from '@/views/AddNewItemView.vue'
import AdminView from '@/views/AdminView.vue'
import AdminListsView from '@/views/Admin/ListsView.vue'
import AdminUsersView from '@/views/Admin/UsersView.vue'
import AdminFeedView from '@/views/Admin/FeedView.vue'
import HomeView from '@/views/HomeView.vue'
import FeedView from '@/views/FeedScrollView.vue'
import ListsListView from '@/views/ListsListView.vue'
import ListView from '@/views/ListView.vue'
import MessageList from '@/views/MessagesListView.vue'
import SearchView from '@/views/SearchView.vue'
import UserList from '@/views/UsersListView.vue'
import UserProfile from '@/views/UserProfileView.vue'
import UserSettings from '@/views/SettingsView.vue'
import LoginPage from '@/views/LoginPage.vue'
import DocumentView from '@/views/DocumentView.vue';
import log from 'loglevel';

const APP_NAME = 'Curalist'

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    beforeEnter: async (to, from, next) => {
      try {
        const redirectRoute = await determineRedirectRoute()
        if (redirectRoute) {
          log.debug("Redirecting to:", redirectRoute);
          next(redirectRoute)
        } else {
          log.debug("Staying on Home page");
          next()
        }
      } catch (error) {
        console.error('Error in home route navigation guard:', error)
        next(error)
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
    meta: { title: 'Not Found' }
  },
  { path: '/add', component: AddNewItemView, meta: { title: 'Add', requiresAuth: true } },
  { path: '/admin', name: 'Admin', component: AdminView, meta: { title: 'Administration', requiresAuth: true, requiresAdmin: true } },
  { path: '/admin/feed', component: AdminFeedView, meta: { title: 'Feed', requiresAuth: true, requiresAdmin: true } },
  { path: '/admin/lists', component: AdminListsView, meta: { title: 'Lists', requiresAuth: true, requiresAdmin: true } },
  { path: '/admin/users', component: AdminUsersView, meta: { title: 'Users', requiresAuth: true, requiresAdmin: true } },
  { path: '/feed', name: 'Feed', component: FeedView, meta: { title: 'Discover' } },
  { path: '/lists', name: 'Lists', component: ListsListView, meta: { title: 'Lists', requiresAuth: true } },
  { path: '/users/:userkey/lists/:listkey', name: 'List', component: ListView, meta: { title: 'List' } },
  { path: '/messages', name: 'Messages', component: MessageList, meta: { title: 'Messages', requiresAuth: true } },
  { path: '/user/:userkey', name: 'UserProfile', component: UserProfile, props: true, meta: { title: 'User Profile' } },
  { path: '/profile', name: 'MyUserProfile', component: UserProfile, props: { isOwnProfile: true }, meta: { title: 'Your profile', requiresAuth: true } },
  { path: '/search', name: 'Search', component: SearchView, meta: { title: 'Search' } },
  { path: '/settings', name: 'Settings', component: UserSettings, meta: { title: 'User Settings', requiresAuth: true } },
  { path: '/users', name: 'Users', component: UserList, meta: { title: 'Curators' } },
  { path: '/login', component: LoginPage, name: 'Login', meta: { title: 'Login' } },
  { path: '/register', component: LoginPage, name: 'Register', meta: { title: 'Register' } },
  {
    path: '/docs/:docName',
    name: 'Doc',
    component: DocumentView,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

async function determineRedirectRoute() {
  let homeComponent = 'Home';
  let title = 'Home';

  // Check if user is authenticated
  const authStatus = await fetchAuthStatus();

  if (authStatus.isAuthenticated) {
    try {

      const settings = await fetchSettings();
      log.debug('Received settings:', settings);

      if (settings && settings.preferences && settings.preferences.startPage) {
        switch (settings.preferences.startPage) {
          case 'home':
            homeComponent = 'Home';
            title = 'Home';
            break;
          case 'feed':
            homeComponent = 'Feed';
            title = 'Feed';
            break;
          case 'my-lists':
            homeComponent = 'Lists';
            title = 'My Lists';
            break;
          default:
            // If startPage is not 'feed' or 'mylists', we'll stay on the Home page
            return null;
        }
      } else {
        log.warn('Settings, preferences, or startPage not properly defined. Using default home page.');
        return null;
      }
    } catch (error) {
      log.error('Error fetching settings:', error);
      return null;
    }
  }
  // Only return a redirect if it's not the Home page
  return homeComponent !== 'Home' ? { name: homeComponent, title: title } : null;
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if user is authenticated
    const authStatus = await fetchAuthStatus();

    log.debug('User status:', authStatus);

    if (!authStatus.isAuthenticated) {
      // If not authenticated, redirect to login page
      log.trace('User not authenticated. Redirecting to login page');
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use the route's meta title, or fall back to the route name
  log.debug(`afterEach to ${to.path} started`)
  const pageTitle = to.meta.title || to.name || 'Untitled Page'
  document.title = `${pageTitle} - ${APP_NAME}`
  log.debug(`Navigation completed. Page title set to: ${document.title}`)
})

export default router