<template>
    <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
    </div>
    <div v-else-if="error" class="error-message">
        <span class="icon-text">
            <span class="icon is-large">
                <i class="fas fa-exclamation-triangle fa-2x"></i>
            </span>
            <span>{{ error }}</span>
        </span>
        <div class="mt-4">
            <button @click="fetchList" class="button is-primary">
                <span class="icon">
                    <i class="fas fa-sync-alt"></i>
                </span>
                <span>Try Again</span>
            </button>
            <button @click="router.push('/')" class="button is-light ml-2">
                <span class="icon">
                    <i class="fas fa-home"></i>
                </span>
                <span>Go to Home</span>
            </button>
        </div>
    </div>
    <div v-else-if="list" class="list-view">

        <div class="theme-header" :style="headerStyle">
            <button v-if="canEdit" class="upload-image-button" @click="openImageUploadModal">
                <FeatherIcon icon="image" />
            </button>
            <div v-if="canEdit" class="header-buttons">
                <button v-if="canEdit" @click="openPublicPrivateModal"
                    class="button is-small is-rounded header-listmode-button"
                    :class="{ 'is-light': !list.isPublic, 'is-success': list.isPublic }">
                    {{ list.isPublic ? 'Public' : 'Private' }}
                </button>
            </div>
        </div>

        <div class="list-info">
            <div class="title-container">
                <div v-if="list.rootList">
                    <h1 class="title">{{ list.rootList.title }}</h1>
                </div>
                <div v-else>
                    <h1 class="title">{{ list.title }}</h1>
                </div>
                <div class="options-menu">
                    <button v-if="canEdit" @click="openEditListDetailsModal" class="options-button button is-primary">
                        <i data-feather="more-vertical"></i>
                    </button>
                </div>
            </div>

            <nav v-if="list.breadcrumb && list.breadcrumb.length > 0" class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li class="clickable" v-for="(item, index) in sortedBreadcrumb" :key="item.position">
                        <a v-if="index === 0"
                            @click.prevent="handleNavigateToSublist({ userkey: item.userkey, listkey: item.listkey })">
                            <FeatherIcon icon="home" size="16" alt="{{ index }}" />
                            Home
                        </a>
                        <a v-else
                            @click.prevent="handleNavigateToSublist({ userkey: item.userkey, listkey: item.listkey })">
                            <FeatherIcon icon="layers" size="16" />
                            {{ item.title }}
                        </a>
                    </li>
                    <li v-if="list.breadcrumb.length > 0" class="is-active">
                        <span>
                            <FeatherIcon icon="layers" size="16" />
                            {{ list.branch.title }}
                        </span>
                    </li>
                </ul>
            </nav>

            <div class="section-menu">
                <div class="section-menu-item" @click="showSection('contents')"
                    :class="{ active: activeSection === 'contents' }">
                    <FeatherIcon icon="list" /> <span class="hide-on-mobile">Contents</span>
                </div>
                <div class="section-menu-item" @click="showSection('details')"
                    :class="{ active: activeSection === 'details' }">
                    <FeatherIcon icon="info" /> <span class="hide-on-mobile">Details</span>
                </div>
                <div class="section-menu-item" @click="cycleDisplayFormat">
                    <FeatherIcon :icon="displayFormatIcon" /> 
                    <span class="hide-on-mobile">{{ displayFormatText }}</span>
                </div>
                <div class="section-menu-item hidden">
                    <FeatherIcon icon="star" /> <span class="hide-on-mobile">Rate</span>
                </div>
                <div class="section-menu-item hidden">
                    <FeatherIcon icon="bookmark" /> <span class="hide-on-mobile">Save</span>
                </div>
                <div class="section-menu-item hidden">
                    <FeatherIcon icon="share" /> <span class="hide-on-mobile">Share</span>
                </div>
                <div class="section-menu-item" @click="refreshListContents">
                    <FeatherIcon icon="refresh-cw" class="refresh-icon"
                        :class="{ 'is-refreshing': isRefreshingContents }" /> <span
                        class="hide-on-mobile">Refresh</span>
                </div>
            </div>

            <div v-if="activeSection === 'details'">
                <div v-if="list.description" class="description">
                    {{ list.description }}
                </div>
                <div class="list-item-count">Total items: {{ totalItemCount }}</div>
                <div class="topics" v-if="list.topics">
                    <span v-for="tag in list.topics" :key="tag" class="tag is-info is-light">
                        {{ tag }}
                    </span>
                </div>
                <div>
                    Curated by:
                </div>
                <UserCard :user="list.author || {}" />
                <div class="info-container">
                    <div class="meta-info">
                        <div>
                            <i class="icon" data-feather="calendar"></i> Created: {{ formatDate(list.createdOn) }}
                        </div>
                        <div><i class="icon" data-feather="calendar"></i> Updated: {{
                            formatDate(list.lastUpdatedOn) }}
                        </div>
                        <div class="hidden">
                            <i class="icon" data-feather="calendar"></i> Shared: {{ formatDate(list.lastUpdatedOn) }}
                        </div>
                        <div class="hidden">
                            <i class="icon" data-feather="blank"></i> Total updates: {{ list.totalUpdates }}
                        </div>
                    </div>
                    <div class="stats-info hidden">
                        <div><i data-feather="users"></i> Followers: {{ list.followersCount }}</div>
                        <div><i data-feather="star"></i> Rating: {{ list.rating != null ? list.rating.toFixed(1) : 'N/A'
                            }}
                            ({{ list.ratingCount }})</div>
                        <div><i data-feather="message-square"></i> Comments: {{ list.commentsCount }}</div>
                    </div>
                </div>
            </div>

            <div v-if="activeSection === 'author'">

            </div>

            <div v-if="activeSection === 'contents'">
                <div class="list-toolbar">
                    <div class="list-actions">
                        <button v-if="canEdit" @click="openAddListModal" class="button is-primary">
                            <i data-feather="folder-plus"></i>
                            <span class="hide-on-mobile">Add Sublist</span>
                        </button>
                        <button v-if="canEdit" @click="addLink" class="button is-info">
                            <i data-feather="plus"></i>
                            <span class="hide-on-mobile">Add Link</span>
                        </button>
                    </div>
                </div>

                <div class="list-contents-container">


                    <div v-if="isRefreshingContents" class="loader-container">
                        <div class="loader"></div>
                    </div>
                    <div v-else-if="list?.items?.length === 0" class="empty-list-message">
                        <div class="message">
                            <FeatherIcon icon="inbox" size="48" />
                            <h3>This list is empty</h3>
                            <p>Start adding items to build your collection!</p>
                        </div>
                    </div>
                    <div v-else class="list-items" :class="displayFormat">
                        <ListItem v-for="item in list?.items || []" :key="item.itemkey" :item="item"
                            :displayFormat="displayFormat" @edit="openEditModal" @delete="openDeleteConfirmation"
                            @report="reportItem" @navigateToSublist="handleNavigateToSublist" />
                    </div>
                </div>
            </div>
        </div>

        <PublicPrivateModal :isActive="showPublicPrivateModal" :isPublic="list?.isPublic"
            @confirm="confirmTogglePublicStatus" @cancel="cancelTogglePublicStatus" />

        <AddLinkModal :isActive="showEditLinkModal" :userkey="currentUserkey" :listkey="currentListkey"
            :itemkey="editingItem?.itemkey" :editingLink="editingItem" @close="closeEditModal"
            @linkUpdated="handleLinkUpdated" @linkAdded="handleLinkUpdated" />

        <AddListModal :isActive="showEditListModal" :user-key="currentUserkey" :parent-list-key="currentListkey"
            :editingList="editingItem" @close="closeAddListModal" @list-added="handleListAdded"
            @list-updated="handleListUpdated" />

        <ConfirmDeleteModal :isActive="showConfirmModal" :isDeleting="isDeletingItem" @confirm="confirmDelete"
            @cancel="cancelDelete" />

        <ImageUploadModal :isActive="isImageUploadModalActive" :userkey="currentUserkey" :listkey="currentListkey"
            @close="closeImageUploadModal" @upload="handleImageUpload" @uploaded="handleImageUploaded" />

    </div>
    <PoweredByCuralist />
</template>

<script setup>
import { ref, onMounted, onUpdated, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getList as apiGetList, deleteItem as apiDeleteItem, setListAccess as apiSetListAccess } from '@/services/api';
import { useAuthStore } from '@/stores/auth';
import { formatDate } from '@/utils/dateUtils';
import ListItem from '@/components/ListItem.vue';
import AddLinkModal from '@/components/AddLinkModal.vue';
import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
import AddListModal from '@/components/AddListModal.vue';
import PublicPrivateModal from '@/components/PublicPrivateModal.vue';
import PoweredByCuralist from '@/components/PoweredByCuralist.vue';
import feather from 'feather-icons';
import log from 'loglevel';
import UserCard from '@/components/UserCard.vue';
import ImageUploadModal from '@/components/ImageUploadModal.vue';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const authStore = useAuthStore(); // Initialize the auth store

const route = useRoute();
const router = useRouter();
const list = ref(null);
const error = ref(null);
const activeSection = ref('contents');
const isRefreshingContents = ref(false);
const showEditLinkModal = ref(false);
const showEditListModal = ref(false);
const editingItem = ref(null);
const rootListkey = ref('');
const currentUserkey = ref('');
const currentListkey = ref('');
const showConfirmModal = ref(false);
const itemToDelete = ref(null);
const isDeletingItem = ref(false);
const openMenuItemKey = ref(null); // New ref to track the open menu
const isLoading = ref(true);
const showPublicPrivateModal = ref(false);
const isTogglingPublicStatus = ref(false);
const isImageUploadModalActive = ref(false);
const displayFormat = ref('single-line');

// Initialize currentUserkey and currentListkey from route params
const initializeFromRoute = () => {
    currentUserkey.value = route.params.userkey || '';
    rootListkey.value = route.params.listkey || '';
    currentListkey.value = route.params.listkey || '';
};

// Call initializeFromRoute when the component is created
initializeFromRoute();

const canEdit = computed(() => {

    log.debug("Current user", authStore.userkey);
    if (!list.value || !authStore.userkey) return false;

    // Check if the current user's userkey matches the list's userkey
    const isOwner = authStore.userkey === list.value.userkey;
    log.debug("Current user is owner", isOwner);

    // Check if the current user has an admin role
    const isAdmin = authStore.roles && authStore.roles.includes('admin');
    log.debug("Current user is admin", isAdmin);

    return isOwner || isAdmin;
});

const sortedBreadcrumb = computed(() => {
    return [...list.value.breadcrumb].sort((a, b) => a.position - b.position);
});

const totalItemCount = computed(() => {
    if (!list.value || !list.value.items) return 0;

    return list.value.items.reduce((total, item) => {
        if (item.itemType === 'link') {
            return total + 1;
        } else if (item.itemType === 'list' && item.totalItems) {
            return total + item.totalItems;
        }
        return total;
    }, 0);
});

const openPublicPrivateModal = () => {
    showPublicPrivateModal.value = true;
};

const cancelTogglePublicStatus = () => {
    showPublicPrivateModal.value = false;
};

const confirmTogglePublicStatus = async () => {
    try {
        isTogglingPublicStatus.value = true;
        // Call your API to toggle the public status
        let newAccessLevel = "";
        if (list.value.isPublic) {
            newAccessLevel = "private";
        } else {
            newAccessLevel = "public";
        }
        log.debug("Changing list access to: ", newAccessLevel);
        await apiSetListAccess(currentUserkey.value, currentListkey.value, newAccessLevel);
        // Update the local state
        list.value.isPublic = !list.value.isPublic;
        showPublicPrivateModal.value = false;
    } catch (error) {
        log.error('Error toggling list public status:', error);
        // Handle the error (e.g., show an error message to the user)
    } finally {
        isTogglingPublicStatus.value = false;
    }
};

//const toggleListPublicStatus = async (userkey, listkey) => {
//  const response = await api.put(`/users/${userkey}/lists/${listkey}/toggle-public`);
//  return response.data;
//};

const fetchList = async () => {
    error.value = null;
    isRefreshingContents.value = true;
    try {
        log.debug('Fetching list with userkey:', currentUserkey.value, 'and listkey:', currentListkey.value);
        if (!currentUserkey.value || !currentListkey.value) {
            throw new Error('Missing userkey or listkey');
        }
        const listData = await apiGetList(currentUserkey.value, currentListkey.value);
        list.value = {
            ...listData,
            items: Array.isArray(listData.items) ? listData.items : [],
            author: listData.author || {},
            topics: Array.isArray(listData.topics) ? listData.topics : [],
            breadcrumb: Array.isArray(listData.breadcrumb) ? listData.breadcrumb : [],
        };
    } catch (err) {
        log.error('Error fetching list:', err);
        if (err.response) {
            switch (err.response.status) {
                case 401:
                    error.value = "You are not authorized to view this list. Please log in or check your permissions.";
                    break;
                case 404:
                    error.value = "The requested list could not be found. It may have been deleted or you may have entered an incorrect URL.";
                    break;
                default:
                    error.value = "An error occurred while fetching the list. Please try again later.";
            }
        } else if (err.request) {
            error.value = "Unable to reach the server. Please check your internet connection and try again.";
        } else {
            error.value = "An unexpected error occurred. Please try again later.";
        }
    } finally {
        isRefreshingContents.value = false;
    }
};

const showSection = (section) => {
    activeSection.value = section;
};

onMounted(() => {
    log.trace("on Mounted starts");
    fetchList();
    isLoading.value = false;
});

// Add a watcher for route changes
watch(() => route.params, (newParams, oldParams) => {
    if (newParams.userkey !== oldParams.userkey || newParams.listkey !== oldParams.listkey) {
        initializeFromRoute();
        fetchList();
    }
});

onUpdated(() => {
    feather.replace();
});

const addLink = () => {
    editingItem.value = null; // Set to null for adding a new link
    showEditLinkModal.value = true;
};

const openEditModal = (item) => {
    editingItem.value = { ...item }; // Create a copy of the item
    log.debug("open edit modal: ", item);
    if (editingItem.value.itemType == 'list') {
        showEditListModal.value = true;
    }
    if (editingItem.value.itemType == 'link') {
        showEditLinkModal.value = true;
    }
};

const closeEditModal = () => {
    showEditLinkModal.value = false;
    showEditListModal.value = false;
    editingItem.value = null;
};

const openDeleteConfirmation = (item) => {
    log.trace("Opening delete confirm modal: ", item);
    itemToDelete.value = item;
    showConfirmModal.value = true;
    openMenuItemKey.value = null; // Close the menu when opening the confirm modal
};

const cancelDelete = () => {
    showConfirmModal.value = false;
    itemToDelete.value = null;
};

const confirmDelete = async () => {
    if (itemToDelete.value) {
        isDeletingItem.value = true;
        try {
            await deleteItem(itemToDelete.value);
            showConfirmModal.value = false;
            itemToDelete.value = null;
        } finally {
            isDeletingItem.value = false;
        }
    }
};

const deleteItem = async (item) => {
    try {
        log.debug('Deleting item:', item);
        if (!item.itemkey) {
            throw new Error('Item key is missing');
        }

        await apiDeleteItem(currentUserkey.value, currentListkey.value, item.itemkey);
        log.debug('Item deleted successfully');

        await refreshListContents();

        // Optionally, show a success message
        log.debug('Item deleted successfully');
    } catch (error) {
        log.error('Error deleting item:', error);
        alert('Failed to delete item. Please try again.');
    }
};

const reportItem = (item) => {
    // Implement report functionality
    console.log('Report item:', item);
};

const refreshListContents = async () => {
    isRefreshingContents.value = true;
    try {
        const { userkey, listkey } = route.params;
        log.debug('Refreshing list contents with userkey:', userkey, 'and listkey:', listkey);
        if (!userkey || !listkey) {
            throw new Error('Missing userkey or listkey');
        }
        const listData = await apiGetList(userkey, listkey);
        // Only update the items array
        list.value.imageUrl = listData.imageUrl;
        list.value.items = Array.isArray(listData.items) ? listData.items : [];
    } catch (err) {
        log.error('Error refreshing list contents:', err);
        // Optionally, show an error message to the user
    } finally {
        isRefreshingContents.value = false;
    }
};

const handleLinkUpdated = async () => {
    await refreshListContents();
    closeEditModal();
};

const openAddListModal = () => {
    showEditListModal.value = true;
    console.log('Opening modal, showEditListModal:', showEditListModal.value);
    console.log('Current userkey:', currentUserkey.value);
    console.log('Current listkey:', currentListkey.value);
};

const closeAddListModal = () => {
    showEditListModal.value = false;
    console.log('Closing modal, showEditListModal:', showEditListModal.value);
};

const handleListAdded = async (newList) => {
    console.log('New sub-list added:', newList);
    // Refresh the list or update the UI here
    await refreshListContents();
};

const handleListUpdated = async (updatedList) => {
    log.debug('Sub-list updated:', updatedList);
    // Refresh the list or update the UI here
    await refreshListContents();
};

const openEditListDetailsModal = () => {
    log.debug('open list details modal:', list.value);
    editingItem.value = list.value;
    showEditListModal.value = true;
};

const handleNavigateToSublist = async ({ userkey, listkey }) => {
    log.debug('Navigating to sublist:', { userkey, listkey });
    currentUserkey.value = userkey;
    currentListkey.value = listkey;
    await fetchList();
    // Optionally update the URL without navigating
    //router.push(`/users/${userkey}/lists/${listkey}`, { replace: true });
};

const headerStyle = computed(() => ({
    backgroundImage: list.value && list.value.imageUrl
        ? `url(${list.value.imageUrl})`
        : 'none',
    // You can add other style properties here if needed
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

const openImageUploadModal = () => {
    isImageUploadModalActive.value = true;
};

const closeImageUploadModal = () => {
    isImageUploadModalActive.value = false;
};

const handleImageUploaded = (imageUrl) => {
    if (list.value) {
        const timestamp = new Date().getTime();
        log.debug("List new image: ", imageUrl);
        list.value.imageUrl = `${imageUrl}?t=${timestamp}`;
    }
    // Optionally, you can show a success message here
};

const cycleDisplayFormat = () => {
    const formats = ['single-line', 'multi-line', 'tile'];
    const currentIndex = formats.indexOf(displayFormat.value);
    displayFormat.value = formats[(currentIndex + 1) % formats.length];
};

const displayFormatText = computed(() => {
  switch (displayFormat.value) {
    case 'single-line': return 'List';
    case 'multi-line': return 'Summary';
    case 'tile': return 'Tiles';
    default: return 'Format';
  }
});

const displayFormatIcon = computed(() => {
    switch (displayFormat.value) {
        case 'single-line': return 'menu';
        case 'multi-line': return 'list';
        case 'tile': return 'grid';
        default: return 'list';
    }
});
</script>

<style scoped>
.refresh-icon {
    /* Your existing button styles */
    transition: transform 0.3s ease;
}

.refresh-icon.is-refreshing {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.list-view {
    max-width: 800px;
    margin: 0 auto;
}

.theme-header {
    position: relative;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    margin-bottom: 0px !important;
}

.header-buttons {
    top: 10px;
    left: 10px;
    display: flex;
    gap: 10px;
}

.button.is-light {
    background-color: #f5f5f5;
    color: #363636;
}

.button.is-success {
    background-color: #48c774;
    color: #fff;
}

.header-listmode-button {
    position: absolute;
    top: 10px;
}

.header-edit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    vertical-align: top;
}

.title-container .title {
    line-height: 1.5;
    margin: 0px;
    padding: 4px;
}

.breadcrumb {
    padding: 0.5rem 0;
    margin-bottom: 0;
    font-size: 0.9rem;
    margin-bottom: 0 !important;
    align-content: center;
    vertical-align: middle;
}

.breadcrumb ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    vertical-align: middle;
}

.breadcrumb a {
    padding: 2px;
    color: grey;
}

.breadcrumb span {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

.breadcrumb li {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    margin: 0px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    color: #999;
    align-items: center;
    vertical-align: middle;
}

.breadcrumb li .icon {
    margin-right: 2px !important;
    margin-left: 2px !important;
}

.breadcrumb li.is-active {
    color: #000;
}

.list-contents-container {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
}

.options-menu {
    display: flex;
    align-items: top;
    vertical-align: top;
    margin-top: 10px;
    margin-right: 10px;
}

.options-button {
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.theme-header {
    height: 200px;
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border-radius: 8px;
}

.list-info {
    margin-bottom: 20px;
}

.info-container {
    display: flex;
    justify-content: space-between;
}

.meta-info,
.stats-info {
    display: block;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #666;
}

.meta-info div {
    display: flex;
    margin: 10px 10px;
}

.meta-info div {
    display: flex;
}

.meta-info .icon {
    margin-right: 10px;
}

.description {
    margin: 20px 10px;
}

.topics {
    margin-bottom: 10px;
}

.tag {
    margin-right: 5px;
    margin-bottom: 5px;
}

.author-info {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.author-info a {
    text-decoration: none;
    color: inherit;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 10px;
    width: 100%;
}

.author-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
}

.author-name {
    align-items: center;
    font-weight: bold;
    font-size: 1.1em;
}

.certified-icon {
    margin-left: 5px;
    color: #3273dc;
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
}

.author-stats {
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
}

.author-stats div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: small;
}

.author-stats i {
    margin-right: 5px;
    width: 1em;
    height: 1em;
}

.list-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    height: fit-content;
}

.list-item-count {
    text-align: center;
}

.list-items>*:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
}

.list-actions {
    display: flex;
    justify-content: flex-end;
}

.list-actions span {
    margin-left: 4px;
}

.button {
    margin-left: 10px;
}

.list-items {
    display: flex;
    flex-direction: column;
}

.list-items .list-item:not(:last-child) {
    border-bottom: solid 1px #e0e0e0;
}

.error-message,
.loading {
    text-align: center;
    margin-top: 50px;
}

.section-menu {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;
    padding: 4px 10px;
    border: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    border-radius: 8px;
    font-size: smaller;
}

.section-menu-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
}

.section-menu-item .icon {
    margin-right: 6px;
}

.progress {
    max-width: 300px;
    margin: 20px auto;
}

.certified-icon {
    margin-left: 5px;
    color: #3273dc;
    /* You can change this to match your color scheme */
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;
}

.button i {
    margin-right: 5px;
}

.loading-contents {
    text-align: center;
    padding: 20px;
}

.loading-contents .progress {
    max-width: 300px;
    margin: 10px auto;
}

.error-message {
    text-align: center;
    margin-top: 50px;
    padding: 20px;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    color: #856404;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
}

.error-message .icon-text {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.error-message .icon-text .icon {
    color: #856404;
    margin: auto;
}

.error-message .button .icon {
    margin-right: 0.5rem;
}

.empty-list-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin: 20px 0;
}

.empty-list-message .message {
    margin-bottom: 20px;
    background-color: white;
}

.empty-list-message h3 {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
}

.empty-list-message p {
    margin-bottom: 20px;
}

.empty-list-message .actions {
    display: flex;
    gap: 10px;
}

.empty-list-message .button {
    display: flex;
    align-items: center;
    gap: 5px;
}

.upload-image-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
}

.upload-image-button:hover {
    background: rgba(255, 255, 255, 0.9);
}

.list-items {
    display: flex;
    flex-direction: column;
}

.list-items.multi-line-format {
    display: flex;
    flex-direction: column;
}

.list-items.tile-format {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}
</style>