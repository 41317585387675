<template>
  <div class="feed-wrapper view-feed" ref="feedWrapper" @scroll="handleScroll">
    <div v-for="item in feedItems" :key="item.id" class="card feed-item mb-5">
      <div class="card-image-container">
        <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
          <div class="card-image">
            <figure class="image">
              <img :src="item.imageUrl" :alt="item.title + ' theme image'" class="theme-image">
            </figure>
          </div>
        </a>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <a :href="'/user/' + item.userkey">
                <figure class="image is-64x64">
                  <img :src="item.authorImage || '/img/default-profile.png'"
                    :alt="item.authorUsername + ' profile picture'" class="is-rounded author-image">
                </figure>
              </a>
            </div>
            <div class="media-content">
              <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
                <p class="title">{{ item.title }}</p>
                <p v-if="item.description" class="description">{{ item.description }}</p>
              </a>
              <p class="subtitle is-6">
                <a :href="'/user/' + item.userkey">
                  <div class="username">
                    @{{ item.username }}
                    <span v-if="item.isVerified" class="icon has-text-info ml-2">
                      <i class="fas fa-check-circle"></i>
                    </span>
                  </div>
                </a>
              </p>
            </div>
          </div>
        </div>

        <div class="content">
          <div v-if="item.topics && item.topics.length > 0" class="topics tags are-medium">
            <a :href="'/search?topic=' + tag" v-for="tag in item.topics" :key="tag" class="tag">#{{ tag }}</a>            
          </div>
          <a :href="'/users/' + item.userkey + '/lists/' + item.listkey">
            <div class="columns is-mobile is-multiline mt-2">
              <div class="column is-half-mobile">
                <span class="icon-text">
                  <span class="icon has-text-info"><i class="fas fa-link"></i></span>
                  <span>{{ item.totalLinks }} links</span>
                </span>
              </div>
              <div class="column is-half-mobile">
                <span class="icon-text">
                  <span class="icon has-text-success"><i class="fas fa-user-friends"></i></span>
                  <span>{{ item.totalFollowers }}</span>
                </span>
              </div>
              <div class="column is-half-mobile">
                <span class="icon-text">
                  <span class="icon has-text-warning"><i class="fas fa-star"></i></span>
                  <span>{{ item.rating }} ({{ item.totalRatings }})</span>
                </span>
              </div>
              <div class="column is-half-mobile">
                <span class="icon-text">
                  <span class="icon has-text-danger"><i class="fas fa-comment"></i></span>
                  <span>{{ item.totalComments }}</span>
                </span>
              </div>
            </div>
            <div class="list-meta">
              <div class="is-size-7 has-text-grey">
                Created: <time>{{ formatDate(item.createdDate) }}</time>
              </div>
              <div class="is-size-7 has-text-grey">
                Last updated: <time>{{ formatDate(item.updatedDate) }}</time>
              </div>
            </div>
          </a>
        </div>
      </div>
      <footer class="card-footer">
        <div class="card-footer-item clickable" @click.prevent="likeItem(item)">
          <span class="icon-text">
            <span class="icon"><i class="far fa-heart"></i></span>
            <span>Like</span>
          </span>
        </div>
        <div class="card-footer-item clickable" @click.prevent="bookmarkItem(item)">
          <span class="icon-text">
            <span class="icon"><i class="far fa-bookmark"></i></span>
            <span>Bookmark</span>
          </span>
        </div>
        <div class="card-footer-item clickable" @click.prevent="shareItem(item)">
          <span class="icon-text">
            <span class="icon"><i class="fas fa-share"></i></span>
            <span>Share</span>
          </span>
        </div>
      </footer>
    </div>

    <div v-if="loading" class="has-text-centered">
      <div class="loader is-loading"></div>
    </div>

    <div v-if="error" class="has-text-centered mt-4">
      <p class="has-text-danger mb-3">{{ error }}</p>
      <button class="button is-primary" @click="retryLoad">Try Again</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { fetchFeed } from '@/services/api';
import log from 'loglevel';

const feedItems = ref([]);
const loading = ref(false);
const error = ref(null);
const page = ref(1);
const hasMore = ref(true);
const feedWrapper = ref(null);

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const loadMoreItems = async () => {

  log.trace('loadMoreItems started')
  if (loading.value || !hasMore.value) return;

  loading.value = true;
  error.value = null;

  try {
    const newItems = await fetchFeed(page.value);
    if (newItems.length === 0) {
      hasMore.value = false;
    } else {
      feedItems.value.push(...newItems);
      page.value++;
    }
  } catch (err) {
    log.error('Error fetching feed items:', err);
    error.value = 'Unable to load feed items. Please try again.';
  } finally {
    loading.value = false;
  }
};

const handleScroll = () => {
  if (!feedWrapper.value) return;

  const { scrollTop, scrollHeight, clientHeight } = feedWrapper.value;
  log.debug('Scroll position:', scrollTop + clientHeight, 'of', scrollHeight);
  if (scrollTop + clientHeight >= scrollHeight - 200 && !loading.value && hasMore.value) {
    log.trace('Loading more items...');
    loadMoreItems();
  }
};

const retryLoad = () => {
  error.value = null;
  loadMoreItems();
};

onMounted(() => {
  loadMoreItems();
});

const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

const likeItem = (item) => {
  // Implement like functionality
  log.debug('Like item:', item.id);
};

const bookmarkItem = (item) => {
  // Implement bookmark functionality
  log.debug('Bookmark item:', item.id);
};

const shareItem = (item) => {
  // Implement share functionality
  log.debug('Share item:', item.id);
};

</script>

<style scoped>
.card-image-container {
  position: relative;
}

.card-image {
  height: 10em;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: solid 1px silver;
}

.card-content {
  position: relative;
  padding-top: 1em;
  /* Adjust this value to accommodate the overlapping profile image */
}

.media-left {
  position: absolute;
  top: -100px;
  /* Half of the profile image height (64px / 2) */
  left: 1em;
  z-index: 1;
}

.media-left .image {
  border: 3px solid silver;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 8em;
  width: 8em;
}

.feed-wrapper {
  height: calc(100vh - 60px);
  /* Adjust based on your header height */
  overflow-y: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  /* Ensure the content is still scrollable */
  overflow-y: scroll;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3273dc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

footer.card-footer {
  background-color: #f5f5f5;
  margin: 0;
}

.columns {
  margin: 1em;
}

.list-meta {
  margin: 1em;
}

.description {
  text-decoration: none;
  color: silver;
  margin: 1em 0 0 0;
}

.username {
  text-decoration: none;
  margin-top: 1em !important;
}

.topics {
  margin: 0 0 0 1em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>