<template>
  <div :class="['list-item', displayFormat]" @click="handleItemClick">
    <template v-if="displayFormat === 'single-line'">
      <div class="item-content">
        <img :src="item.iconUrl" :alt="item.title" class="item-icon">
        <div class="item-info">
          <div class="item-header">
            <div class="title-wrapper">
              <h3 class="item-title" v-html="decodedTitle"></h3>
            </div>
            <button @click.stop="toggleMenu" class="button is-small is-primary rounded">
              <span class="icon">
                <FeatherIcon icon="more-vertical" size="16" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="displayFormat === 'multi-line'">
      <div class="item-content">
        <img :src="item.iconUrl" :alt="item.title" class="item-icon">
        <div class="item-info">
          <div class="item-header">
            <div class="title-wrapper">
              <h3 class="item-title" v-html="decodedTitle"></h3>
            </div>
            <button @click.stop="toggleMenu" class="button is-small is-primary rounded">
              <span class="icon">
                <FeatherIcon icon="more-vertical" size="16" />
              </span>
            </button>
          </div>
          <p v-if="item.description" class="item-description" v-html="decodedDescription"></p>
          <div class="item-topics">
            <span v-for="topic in item.topics" :key="topic" class="topic-tag">
              #{{ topic }}
            </span>
          </div>
          <div v-if="item.itemType === 'link'" class="item-url-container">
            <span class="item-url" :title="item.url">{{ item.url }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="displayFormat === 'tile'">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <p v-if="item.iconUrl" class="image"><img :src="item.iconUrl" /></p>
          <p class="title">{{ item.title }}</p>
          <p class="subtitle">{{ item.description }}</p>
          <button @click.stop="toggleMenu" class="button is-small is-primary rounded">
            <span class="icon">
              <FeatherIcon icon="more-vertical" size="16" />
            </span>
          </button>
        </article>
      </div>
    </template>
  </div>

  <!-- Modal Menu -->
  <div class="modal" :class="{ 'is-active': isMenuVisible }">
    <div class="modal-background" @click="closeMenu"></div>
    <div class="modal-content">
      <div class="card">
        <div class="card-header">
          <h3 class="card-header-title">Select</h3>
          <button class="delete" aria-label="close" @click="closeMenu"></button>
        </div>
        <div class="card-content">
          <button v-if="canEdit" @click.stop="editItem" class="button is-fullwidth is-text">
            <FeatherIcon icon="edit" size="24" />
            <div>Edit</div>
          </button>
          <button v-if="canEdit" @click.stop="$emit('delete', item)" class="button is-fullwidth is-text">
            <FeatherIcon icon="trash-2" size="24" />
            <div>Delete</div>
          </button>
          <button @click.stop="$emit('report', item)" class="button is-fullwidth is-text">
            <FeatherIcon icon="flag" size="24" />
            <div>Report</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import FeatherIcon from './FeatherIcon.vue';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const authStore = useAuthStore(); // Initialize the auth store

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  displayFormat: {
    type: String,
    default: 'single-line',
    validator: (value) => ['single-line', 'multi-line', 'tile'].includes(value)
  }
});

const canEdit = computed(() => {

  log.debug("Current user", authStore.userkey);
  if (!props.item || !authStore.userkey) return false;

  // Check if the current user's userkey matches the list's userkey
  const isOwner = authStore.userkey === props.item.userkey;
  log.debug("Current user is owner", isOwner);

  // Check if the current user has an admin role
  const isAdmin = authStore.roles && authStore.roles.includes('admin');
  log.debug("Current user is admin", isAdmin);

  return isOwner || isAdmin;
});


const emit = defineEmits(['edit', 'delete', 'report', 'navigateToSublist']);

//const router = useRouter();
const isMenuVisible = ref(false);

const toggleMenu = () => {
  log.debug("toggle the menu:");
  isMenuVisible.value = !isMenuVisible.value;
  log.debug("Menu visibility:", isMenuVisible.value);
};

const closeMenu = () => {
  isMenuVisible.value = false;
};

const editItem = () => {
  log.debug("Edit item: ", props.item);
  emit('edit', props.item);
  closeMenu();
};

const handleItemClick = () => {
  const itemType = props.item.itemType?.toLowerCase();
  log.debug("List item clicked.", props.item);
  if (itemType === 'list') {
    emit('navigateToSublist', {
      userkey: props.item.userkey,
      listkey: props.item.itemkey
    });
  } else if (itemType === 'link') {
    window.open(props.item.url, '_blank', 'noopener,noreferrer');
  }
};

const decodedTitle = computed(() => {
  var title = decodeHTMLEntities(props.item.title);
  if (props.item.itemType == "list") {
    title += ` (${props.item.totalItems})`;
  }
  return title;
});

const decodedDescription = computed(() => {
  if (!props.item.description) return '';
  return decodeHTMLEntities(props.item.description);
});

function decodeHTMLEntities(text) {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = text;
  return textArea.value;
}
</script>

<style scoped>
.list-item {
  display: flex;
  padding: 8px;
  background-color: #ffffff;
  cursor: pointer;
}

.item-content {
  display: flex;
  width: 100%;
}

.item-icon {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.single-line .item-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.single-line .item-title {
  font-weight: normal;
  line-height: 24px;
}

.single-line .item-header {
  margin-bottom: 0;
  vertical-align: bottom;
}

.single-line .button {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
}

.item-info {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  /* Allow flexbox to shrink this element */
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.title-wrapper {
  flex-grow: 1;
  min-width: 0;
  /* Allow flexbox to shrink this element */
  margin-right: 8px;
}

.item-title {
  font-weight: 600;
  margin: 0;
  line-height: 1.2em;
  max-height: 2.4em;
  /* 2 lines * 1.2em line-height */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-overflow: ellipsis;
}

.item-description {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.4em;
}

.item-url-container {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.url-icon {
  flex-shrink: 0;
  margin-right: 4px;
}

.item-url {
  font-size: 0.8em;
  color: #3273dc;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.item-url .feather {
  flex-shrink: 0;
  margin-right: 4px;
}

.button.rounded {
  border-radius: 50%;
  padding: 0;
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 0;
  /* Ensure no top margin */
}

.item-topics {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.topic-tag {
  font-size: 0.8em;
  color: #3273dc;
  background-color: #f0f4ff;
  padding: 2px 8px;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.topic-tag:hover {
  background-color: #e0e8ff;
}

.modal-content {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.card-header {
  align-items: center;
  padding-right: 20px;
}

.card-content {
  padding: 1rem;
}

.button.is-fullwidth {
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}

.button.is-fullwidth:last-child {
  margin-bottom: 0;
}

@media (max-width: 480px) {
  .item-title {
    font-size: 1em;
  }

  .button.rounded {
    width: 28px;
    height: 28px;
    min-width: 28px;
  }
}

.list-item.card {
  width: calc(50% - 1rem);
  margin-bottom: 1rem;
}

.list-item.tile {
  width: 100px;
}

.tile-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1rem;
}

.tile {
  min-width: 180px;
}
</style>