<template>
  <h1>{{ isAllowedToEdit ? 'Your profile' : 'User profile' }}</h1>
  <div class="column is-12 view-userprofile">
    <div class="card">
      <div class="card-image">
        <figure class="image is-3by1">
          <img :src="user.profileHeader" :alt="user.name">
        </figure>
        <a v-if="isOwnProfile" class="button is-primary is-small is-rounded edit-button header-edit-button">
          <span class="icon">
            <FeatherIcon icon="edit" :size="16" />
          </span>
        </a>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column is-3">
            <div class="profile-photo-container">
              <figure class="image is-128x128 mb-4">
                <img :src="user.profilePhoto" :alt="user.name" class="is-rounded">
                <a v-if="isAllowedToEdit" class="button is-primary is-small is-rounded edit-button profile-photo-edit-button">
                  <span class="icon">
                    <FeatherIcon icon="edit" :size="16" />
                  </span>
                </a>
              </figure>
            </div>
            <!-- Only show edit and logout buttons on own profile -->
            <div v-if="isAllowedToEdit" class="content">
              <button @click="editProfile" class="button is-primary is-halfwidth">Edit</button>
              <button @click="handleLogout" class="button is-danger is-halfwidth">Logout</button>
            </div>
          </div>
          <div class="column is-9">
            <div class="media-content">
              <div class="level">
                <div class="level-left">
                  <div>
                    <p class="title is-4">{{ user.name }}</p>
                    <p class="subtitle is-6">{{ user.username }}</p>
                    <p class="has-text-grey-light is-size-7">Joined on {{ formatDate(user.joinDate) }}</p>
                  </div>
                </div>
                <div class="level-right">
                  <!-- Only show follow, message, and more options on other user's profile -->
                  <div v-if="!isOwnProfile" class="buttons">
                    <button class="button is-primary" @click="followUser">
                      <span class="icon">
                        <i class="fas fa-user-plus"></i>
                      </span>
                      <span>Follow</span>
                    </button>
                    <button class="button is-info" @click="messageUser">
                      <span class="icon">
                        <i class="fas fa-envelope"></i>
                      </span>
                      <span>Message</span>
                    </button>
                    <div class="dropdown is-right is-hoverable">
                      <div class="dropdown-trigger">
                        <button class="button is-primary is-rounded" aria-haspopup="true" aria-controls="dropdown-menu">
                          <span class="icon">
                            <FeatherIcon icon="more-vertical" :size="24" />
                          </span>
                        </button>
                      </div>
                      <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                          <a href="#" class="dropdown-item" @click.prevent="reportUser">
                            Report User
                          </a>
                          <a href="#" class="dropdown-item" @click.prevent="blockUser">
                            Block User
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content">
              <p>{{ user.bio }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column is-3">
            <div class="content">
              <h4 class="title is-5">Badges</h4>
              <div class="tags">
                <span class="tag is-primary" title="Power User">🏆</span>
                <span class="tag is-info" title="Top Contributor">🌟</span>
                <span class="tag is-warning" title="Trendsetter">🔥</span>
              </div>
            </div>
            <div class="content">
              <div class="level is-mobile">
                <div class="level-left">
                  <h4 class="title is-5 mb-0">Links</h4>
                  <a v-if="isAllowedToEdit" class="button is-primary is-small is-rounded edit-button">
                    <span class="icon">
                      <FeatherIcon icon="edit" :size="16" />
                    </span>
                  </a>
                </div>
              </div>
              <div class="social-links mt-2">
                <a href="#" class="button is-info is-outlined is-fullwidth mb-2">
                  <span class="icon">
                    <i class="fab fa-twitter"></i>
                  </span>
                  <span>Twitter</span>
                </a>
                <a href="#" class="button is-danger is-outlined is-fullwidth mb-2">
                  <span class="icon">
                    <i class="fab fa-instagram"></i>
                  </span>
                  <span>Instagram</span>
                </a>
                <a href="#" class="button is-link is-outlined is-fullwidth mb-2">
                  <span class="icon">
                    <i class="fab fa-linkedin"></i>
                  </span>
                  <span>LinkedIn</span>
                </a>
                <a href="#" class="button is-dark is-outlined is-fullwidth">
                  <span class="icon">
                    <i class="fab fa-github"></i>
                  </span>
                  <span>GitHub</span>
                </a>
              </div>
            </div>
          </div>
          <div class="column is-9">
            <div class="content">
              <div class="level is-mobile">
                <div class="level-left">
                  <h4 class="title is-5 mb-0">Favorite Topics</h4>
                  <a v-if="isAllowedToEdit" class="button is-primary is-small is-rounded edit-button">
                    <span class="icon">
                      <FeatherIcon icon="edit" :size="16" />
                    </span>
                  </a>
                </div>
              </div>
              <div class="tags mt-2">
                <span v-for="topic in user.favoriteTopics" :key="topic" class="tag is-link">#{{ topic }}</span>
              </div>
            </div>
            <div class="columns is-mobile">
              <div class="column">
                <div class="stat-container">
                  <div class="stat-header">Lists Shared</div>
                  <div class="stat-value">{{ user.totalListsShared }}</div>
                </div>
              </div>
              <div class="column">
                <div class="stat-container">
                  <div class="stat-header">Followers</div>
                  <div class="stat-value">{{ user.followers }}</div>
                </div>
              </div>
              <div class="column">
                <div class="stat-container">
                  <div class="stat-header">Following</div>
                  <div class="stat-value">{{ user.following }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="stat-container">
              <div class="stat-header">Most Popular Lists</div>
              <div>
                <ul class="list-items">
                  <li v-for="list in user.popularLists" :key="list.id" @click="goToList(list.id)" class="clickable">
                    <span class="icon list-icon">
                      <FeatherIcon :icon="list.icon" :size="24" />
                    </span>
                    <div class="list-item-content">
                      <span>{{ list.title }}</span>
                      <span class="tag is-small is-info">{{ list.views }} views</span>
                    </div>
                    <span class="icon is-small"><i class="fas fa-chevron-right"></i></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="stat-container">
              <div class="stat-header">Favorite Lists</div>
              <div>
                <ul class="list-items">
                  <li v-for="list in user.favoriteLists" :key="list.id" @click="goToList(list.userkey, list.listkey)">
                    <div class="icon list-icon">
                      <FeatherIcon :icon="list.icon" :size="24" />
                    </div>
                    <div class="list-item-content">
                      <div>{{ list.title }}</div>
                      <div>
                        <div class="list-item-author">
                          <img :src="list.authorImage" :alt="list.authorUsername" class="is-rounded">
                          <span>by @{{ list.authorUsername }}</span>
                        </div>
                      </div>
                    </div>
                    <span class="icon is-small"><i class="fas fa-chevron-right"></i></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth'
import { fetchUserProfile } from '@/services/api';
import log from 'loglevel';

// Set the log level from config
log.setLevel(process.env.VUE_APP_LOG_LEVEL);

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore()

const user = ref({
  name: '',
  username: '',
  email: '',
  profilePhoto: '',
  bio: '',
  joinDate: '',
  favoriteTopics: [],
  totalListsShared: 0,
  followers: 0,
  following: 0
});

const isAllowedToEdit = computed(() => {
  // Check if it's the user's own profile
  const isOwnProfile = route.name === 'MyUserProfile';
  
  // Check if the current user is an admin (you'll need to implement this check)
  const isAdmin = authStore.isAdmin; // Assuming you have this method in your auth store
  
  return isOwnProfile || isAdmin;
});

onMounted(async () => {
  try {
    let userData;
    if (route.name === 'MyUserProfile') {
      userData = await fetchUserProfile();
    } else {
      const userkey = route.params.userkey;
      userData = await fetchUserProfile(userkey);
    }
    user.value = userData;
  } catch (error) {
    log.error('Failed to fetch user data:', error);
  }
});

const formatDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
};

const editProfile = () => {
  // Only allow editing if it's the user's own profile
  if (isAllowedToEdit.value) {
    log.trace('Edit profile clicked');
    // Implement edit profile functionality
  }
};

const handleLogout = async () => {
  // Only allow logout if it's the user's own profile
  if (route.name === 'MyUserProfile') {
    try {
      await authStore.logoutUser()
      router.push('/')
    } catch (error) {
      console.error('Logout failed:', error)
      // Handle error (e.g., show error message to user)
    }
  }
}

const goToList = (userkey, listkey) => {
  router.push(`/users/${userkey}/lists/${listkey}`);
};

const followUser = () => {
  log.trace('Follow user clicked');
  // Implement follow user functionality
};

const messageUser = () => {
  log.trace('Message user clicked');
  // Implement message user functionality
};

const reportUser = () => {
  log.trace('Report user clicked');
  // Implement report user functionality
};

const blockUser = () => {
  log.trace('Block user clicked');
  // Implement block user functionality
};
</script>

<style scoped>
.media-content {
  overflow-x: unset;
}

.button.is-rounded {
  padding: 16px;
  width: 24px;
  height: 24px;
}

 .profile-photo-container {
  display: flex;
  justify-content: center;
  margin-top: -64px;
  margin-bottom: 1rem;
}

   .profile-photo-container .image {
      z-index: 1;
  }

 .stat-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

 .stat-header {
  background-color: #4a4a4a;
  color: #ffffff;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
}

 .stat-value {
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.stat-container li{
  padding: 8px 10px;
}

 .section-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

 .section-header {
  background-color: #4a4a4a;
  color: #ffffff;
  padding: 0.5rem;
  text-align: center;
}

.section-container .content ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

 .edit-button {
  margin-left: 10px;
}

 .list-items {
  list-style-type: none;
  padding: 0;
}

   .list-items li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e0e0e0;
      cursor: pointer;
  }

       .list-items li .list-icon {
          align-self: flex-start
      }

       .list-items li:last-child {
          border-bottom: none;
      }

.list-icon {
  margin-right: 0.5rem;
  color: #3273dc;
  vertical-align: top;
}

.list-item-content {
  flex-grow: 1;
  display: inline-block;
}

.list-item-author {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #666;
}

  .list-item-author img {
      width: 24px;
      height: 24px;
      margin-right: 0.5rem;
      border-radius: 50%;
  }

.dropdown-trigger .button {
  border-radius: 50% !important;
  padding: auto;
}

.social-links .button {
  justify-content: flex-start;
}

  .social-links .button .icon {
      margin-right: 0.5rem;
  }

.header-edit-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  color: #4a4a4a;
  transition: background-color 0.3s, color 0.3s;
  padding: auto;
}

  .header-edit-button:hover {
      background-color: rgba(255, 255, 255, 0.9);
      color: #363636;
  }

.profile-photo-container {
  display: flex;
  justify-content: center;
  margin-top: -64px;
  margin-bottom: 1rem;
}

.profile-photo-container .button {
  width:  24px;
  height: 24px;
}

  .profile-photo-container .image {
      position: relative;
      z-index: 1;
  }

  .profile-photo-container img {
      border: 4px solid #fff;
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  }

.profile-photo-edit-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #fff;
  border: 2px solid #f5f5f5;
  color: #4a4a4a;
  transition: background-color 0.3s, color 0.3s;
}

  .profile-photo-edit-button:hover {
      background-color: #f5f5f5;
      color: #363636;
  }

  .profile-photo-edit-button .icon {
      font-size: 0.75rem;
  }

</style>